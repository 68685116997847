import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  FormHelperText,
  TextField,
  IconButton,
} from "@material-ui/core";
import {} from "react-feather";
import { Instagram, Twitter } from "@material-ui/icons";
import { GrFacebookOption } from "react-icons/gr";
import Scroll from "react-scroll";
import { FaDiscord } from "react-icons/fa";
import { useHistroy } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ApiConfig from "src/config/APICongig";
import axios from "axios";
import { toast } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { Form, Formik } from "formik";
import * as yep from "yup";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  textFeild: {
    backgroundColor: "#2F2F2F",
    fontFamily: "'Ubuntu', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "11px",
    "& hover": {
      borderRadius: "50px",
    },

    // paddingRight:'0px',
    // padding:"5px 15px 10px 15px",
    borderRadius: "50px",
    // 1borderRadius:"25px",
    "& input": {
      color: "white",
      // backgroundColor:"#2F2F2F"
      padding: "10px 0px 10px 15px",
    },
  },
  footerSection: {
    padding: "15px 0px 45px",
    background: "#000",
    borderTop: "1px solid #4B4B4B",
    "& p": {
      [theme.breakpoints.down("md")]: {
        fontSize: "11px !important",
      },
    },
  },
  textBtn: {
    color: "#FFFFFF",
    width: "200px",
    fontSize: "17px",
    fontFamily: "'Work Sans'",
    border: "1px solid #73FF71",
    margin: "0px -13px 0px 0px",
    background: "#1EB808",
    boxShadow: "0px 0px 5px 1px #1eb808",
    borderRadius: "55px",
    fontWeight: "800",
    "&:hover": {
      background: "#1EB808",
    },
  },
  menuButton: {
    fontSize: "14px",
    // lineHeight: "35px",
    cursor: "pointer",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    color: "#fff",
    fontFamily: "'Saira Semi Condensed', sans-serif",
    letterSpacing: "1px",
    margin: "0 5px",
    [theme.breakpoints.only("xs")]: {
      letterSpacing: "0px",
    },
    "@media (max-width: 1280px)": {
      fontSize: "11px",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
    },
    "&.active": {
      color: "#000",
    },
    "&:hover": {
      color: "#1EB808",
    },
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "20px 0px 50px",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
      padding: "20px 0px 10px",
    },
  },
  socialIcons: {
    color: "white",
    "&:hover": {
      color: "#1EB808",
    },
  },
  center: {
    display: "block",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContents: "center",
      alignItems: "center",
    },
  },
  bottomText: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const classes = useStyles();
  const [email, setemail] = useState();
  const [Loader, setLoader] = useState();
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("You have entered an invalid email address. Please try again")
      .required("Email address is required")
      .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
  });

  const formInitialValue = {
    email: "",
  };
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("file", values.email);
      // formData("email" : email)
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.subscribeNow,
        data: {
          email: values.email,
        },
      });
      console.log("window.sessionStorage.getItem", res);
      if (res.data.statusCode === 200) {
        setLoader(false);
        toast.success(res.data.responseMessage);
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      console.log("ERROR", error);
      // toast.error(error.data.message);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.data.message);
      }
    }
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container>
          <Grid container spacing={2} style={{ paddingTop: "20px" }}>
            <Grid item xs={12} sm={7} md={7} lg={7} className={classes.center}>
              <Box style={{ maxWidth: "120px" }}>
                <img src="./images/logo.png" alt="Logo" width="100%" />
              </Box>
              <Typography
                variant="body1"
                style={{
                  fontSize: "12px",
                  margin: "30px 0 8px",
                }}
              >
                Subscribe for updates and announcements
              </Typography>
              <Formik
                initialValues={formInitialValue}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  // contact,
                  setFieldValue,
                }) => (
                  <Form>
                    <Box>
                      {/* <Typography variant="body1">Email Address</Typography> */}
                      <TextField
                        placeholder="Enter your email"
                        // variant="outlined"
                        name="email"
                        autoComplete="off"
                        // fullWidth
                        className={classes.textFeild}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <Button
                              className={classes.textBtn}
                              // onClick={handleSubmit}
                              type="submit"
                              disabled={Loader}
                            >
                              Subscribe {Loader && <ButtonCircularProgress />}
                            </Button>
                          ),
                        }}
                      />
                      <FormHelperText
                        error
                        style={{ margin: "0px", fontSize: "12px" }}
                      >
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>

                    {/* <Box mt={2} align="right">
                      <Button variant="contained" type="submit" color="primary">
                        Submit{Loader && <ButtonCircularProgress />}
                      </Button>
                    </Box> */}
                  </Form>
                )}
              </Formik>
              {/* <TextField
                placeholder="Enter your email"
                className={classes.textFeild}
                values={email}
                onChange={(shi) => setemail(shi.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button
                      className={classes.textBtn}
                      onClick={handleSubmit}
                      disabled={Loader}
                    >
                      Subscribe {Loader && <ButtonCircularProgress />}
                    </Button>
                  ),
                }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.center}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: "40px",
                }}
              >
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="about"
                  onClick={() => {
                    history.push({
                      pathname: "/about",
                    });
                  }}
                >
                  {" "}
                  ABOUT{" "}
                </ScrollLink>
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/terms",
                    });
                  }}
                >
                  {" "}
                  Terms & Conditions{" "}
                </ScrollLink>
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="policy"
                  onClick={() => {
                    history.push({
                      pathname: "/policy",
                    });
                  }}
                >
                  {" "}
                  Privacy Policy{" "}
                </ScrollLink>
                {/* <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/contact",
                    });
                  }}
                >
                  {" "}
                  Contact{" "}
                </ScrollLink> */}
                {/* <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/terms",
                    });
                  }}
                >
                  {" "}
                  Terms & Condotions{" "}
                </ScrollLink> */}
                {/* <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/policy",
                    });
                  }}
                >
                  {" "}
                  Privacy Policy{" "}
                </ScrollLink> */}
                {/* <ScrollLink
                  className={classes.menuButton}
                  target="_blank"
                  href="https://discord.com/"
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  WHITEPAPER{" "}
                </ScrollLink> */}
                <a
                  className={classes.menuButton}
                  target="_blank"
                  href="https://docs.google.com/document/d/1dIfI2Sy_euzhjGbjs0KtTRHcr_JJ-FVD9zDySMJM9II/edit"
                >
                  WHITEPAPER{" "}
                </a>
              </Box>
              <Box className={classes.icons}>
                <Box display="flex">
                  {/* <Box>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      target="_blank"
                      href="https://discord.com/"
                      className={classes.joinButton}
                    >
                      {" "}
                      JOIN OUR DISCORD{" "}
                    </Button>
                  </Box> */}
                  <Box>
                    <IconButton target="_blank" href="https://discord.com/">
                      <FaDiscord className={classes.socialIcons} />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      href="https://www.facebook.com/"
                    >
                      <GrFacebookOption className={classes.socialIcons} />
                    </IconButton>

                    <IconButton target="_blank" href="https://www.twitter.com/">
                      <Twitter className={classes.socialIcons} />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <Instagram className={classes.socialIcons} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box style={{}} className={classes.bottomText}>
                <Typography
                  variant="body2"
                  style={{ fontSize: "14px", fontWeight: "300" }}
                >
                  ©2022 -moonstep | All rights reserved
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
