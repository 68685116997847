export const NetworkContextName = "Smart Chain - Testnet";
export const ACTIVE_NETWORK = 97;
export const NftContractAddress = "0x122E82B31A7C13137d455fC45C1D7484c5846168";
export const NftStakingAddress = "0x06192323Fb5F19280B0FEb66eE4B98F0AAb9e826";

export const CoinStakingAddress = "0x06f5f352902ce7f1f3350568ad4187a52478e5a8";

export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";

export const NetworkDetails = [
  {
    chainId: "97",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "Smart Chain - Testnet",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.bscscan.com"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
];
