const url = "https://node-move2earn.mobiloitte.com";
// const url = "http://172.16.1.172:1930";

const nft = `${url}/api/v1/nft`;
const user = `${url}/api/v1/user`;
const stake = `${url}/api/v1/stake`;
const contactUs = `${url}/api/v1/contactUs`;
const updateSubscribe = `${url}/api/v1/updateSubscribe`;

const ApiConfig = {
  connectWallet: `${user}/connectWallet`,
  subscribeNow: `${user}/subscribeNow`,
  latestcontactUs: `${contactUs}/contactUs`,

  createNFT: `${nft}/createNFT`,
  listNFT: `${nft}/listNFT`,
  stakeNFT: `${stake}/stakeNFT`,
  stakeList: `${stake}/stakeList`,
  unstakeNFT: `${stake}/unstakeNFT`,
  userContactUs: `${contactUs}/userContactUs`,
  userUpdateSubscribe: `${updateSubscribe}/userUpdateSubscribe`,
};
export default ApiConfig;
